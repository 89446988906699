import React from 'react';
import styled from 'styled-components';

import kalaoLogo from 'assets/Kalao_logo.svg';

const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  padding: 0 30px;

  @media screen and (max-width: 550px) {
    padding: 0 20px;
  }
`;

const NavLogo = styled.img`
  height: 50px;
`;
const Navbar: React.FC = () => {
  return (
    <Nav>
      <a href='/'>
        <NavLogo src={kalaoLogo} />
      </a>
    </Nav>
  );
};

export default Navbar;

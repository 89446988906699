import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Web3 from 'web3';

import * as vestingABI from 'abi/vesting.json';
import mediumLogo from 'assets/medium.svg';
import metamaskIcon from 'assets/metamask-fox.svg';
import {
  CHAIN_ID,
  CHAIN_ID_STRING,
  CHAIN_NAME,
  CONTRACT_ADDRESS,
  RPC_URL,
} from 'utils/constants';

// eslint-disable-next-line
declare let window: any;

const Container = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 0 auto;
`;
const Header = styled.h1`
  color: #1d3561;
`;

const SubHeader = styled.h2`
  color: #1d3561;
`;

const SubHeaderText = styled.p`
  margin: 0;
  color: #646464;
  font-size: 17px;
`;

const ClaimContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f3f3f3;
  border-radius: 5px;
  width: 100%;
  margin: 45px 15px 0 15px;
  padding: 70px 40px;
  box-sizing: border-box;

  @media screen and (max-width: 730px) {
    flex-direction: column;
    padding: 25px 25px;
  }
`;

const MetamaskIcon = styled.img`
  height: 30px;
  margin-right: 10px;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1d3561;
  color: white;
  padding: 10px 15px;
  border-radius: 8px;
  font-size: 16px;
  min-width: 90px;
  @media screen and (max-width: 730px) {
    margin-top: 15px;
  }
`;

const connectWallet = async (): Promise<boolean> => {
  if (window.ethereum) {
    try {
      //   await window.ethereum.send('eth_requestAccounts');
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      return true;
    } catch {
      // Connection rejected
      return false;
    }
  } else {
    // No web3 wallet
    return false;
  }
};

const Claim: React.FC = () => {
  const [connected, setConnected] = useState(false);
  const [address, setAddress] = useState('');
  const [rightChainId, setRightChainId] = useState(false);
  const [unclaimedAllocation, setUnclaimedAllocation] = useState(0);
  const [toClaim, setToClaim] = useState(0);

  const connect = async () => {
    if (connected) return;
    const isConnected = await connectWallet();
    if (isConnected) {
      setConnected(true);
      const _address = await window.web3.eth.getAccounts();
      setAddress(_address[0]);
      const _chainID = await window.web3.eth.getChainId();
      setRightChainId(_chainID === CHAIN_ID);
    }
  };

  const getUnclaimedAllocation = async () => {
    const vestingContract = new window.web3.eth.Contract(
      // eslint-disable-next-line
      vestingABI.abi as any,
      CONTRACT_ADDRESS,
    );

    try {
      const accounts = await window.web3.eth.getAccounts();
      const unclaimed = await vestingContract.methods
        .unclaimedAllocation(accounts[0])
        .call();
      setUnclaimedAllocation(
        parseFloat(Web3.utils.fromWei(`${unclaimed}`, 'ether')),
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getToClaim = async () => {
    const vestingContract = new window.web3.eth.Contract(
      // eslint-disable-next-line
      vestingABI.abi as any,
      CONTRACT_ADDRESS,
    );

    try {
      const accounts = await window.web3.eth.getAccounts();
      const claim = await vestingContract.methods
        .calculateVestingClaim(accounts[0])
        .call();
      setToClaim(parseFloat(Web3.utils.fromWei(`${claim}`, 'ether')));
    } catch (error) {
      console.log(error);
    }
  };

  const claim = async () => {
    if (!toClaim) return;
    const vestingContract = new window.web3.eth.Contract(
      // eslint-disable-next-line
      vestingABI.abi as any,
      CONTRACT_ADDRESS,
    );
    try {
      const accounts = await window.web3.eth.getAccounts();
      await vestingContract.methods
        .claimVestedTokens()
        .send({ from: accounts[0] });
      setToClaim(0);
    } catch (error) {
      console.log(error);
    }
  };

  const onButtonClick = async () => {
    if (connected) {
      if (!rightChainId) {
        const data = [
          {
            chainId: CHAIN_ID_STRING,
            chainName: CHAIN_NAME,
            nativeCurrency: {
              name: 'AVAX',
              symbol: 'AVAX',
              decimals: 18,
            },
            rpcUrls: [RPC_URL],
          },
        ];

        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: data,
          });
        } catch (exception) {
          console.error(exception);
        }
        return;
      }
    }
    connect();
    getUnclaimedAllocation();
    getToClaim();
  };

  useEffect(() => {
    (async () => {
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
        await connect();
        if (!toClaim) await getUnclaimedAllocation();
        await getToClaim();

        // LISTENERS
        window.ethereum.on('chainChanged', (chainId: string) => {
          setRightChainId(parseInt(chainId, 16) === CHAIN_ID);
        });
        window.ethereum.on('accountsChanged', (accounts: Array<string>) => {
          if (!accounts[0]) {
            setConnected(false);
            return;
          }

          setAddress(accounts[0]);
        });
      } else console.log('No web3 wallet');
    })();
  }, [address, rightChainId, toClaim]);

  return (
    <Container>
      <Header>
        Connect your account and claim your tokens
        <span style={{ color: '#61c2e1' }}>.</span>
      </Header>
      <SubHeader>Team</SubHeader>
      <ClaimContainer>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{ fontWeight: 500 }}>{address}</span>
          <span style={{ marginTop: '8px' }}>
            Locked allocation:{' '}
            {unclaimedAllocation.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{' '}
            $KLO
          </span>
          <span style={{ marginTop: '8px' }}>
            Unclaimed allocation:{' '}
            {toClaim.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{' '}
            $KLO
          </span>
        </div>
        {connected ? (
          rightChainId ? (
            <Button
              style={{
                cursor: toClaim ? 'pointer' : 'not-allowed',
              }}
              onClick={claim}
            >
              Claim your $KLO
            </Button>
          ) : (
            <Button onClick={onButtonClick}>
              <MetamaskIcon alt='metamask-icon' src={metamaskIcon} />
              Switch to Avalanche C-Chain
            </Button>
          )
        ) : (
          <Button onClick={onButtonClick}>
            <MetamaskIcon alt='metamask-icon' src={metamaskIcon} />
            Connect to Metamask
          </Button>
        )}
      </ClaimContainer>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
        <img src={mediumLogo} style={{ width: '32px', marginRight: '8px' }} />
        <SubHeaderText>
          How to claim the Kalao sales and bounty rewards
        </SubHeaderText>
      </div>
    </Container>
  );
};

export default Claim;
